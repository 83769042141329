"use client";
import {
  type ITracer,
  BenjiTracerPlugin,
  ClientSamplingTracerPlugin,
  CWVTracerPlugin,
  DocumentLoadTracerPlugin,
  FetchTracerPlugin,
  LongTaskTracerPlugin,
  UncaughtErrorTracerPlugin,
  tracerProvider,
} from "@vzmi/otel-browser";
import { useEffect, type FC } from "react";
import { type OtelBrowserConfig } from "@/configs/otelBrowser";
import { useConfigContext } from "@/contexts/ConfigContext";
import { type RequestContext } from "@/lib/request/types";

type Props = {
  requestContext: RequestContext;
};

function isValidConfig(config: OtelBrowserConfig) {
  return !!config.serviceName && !!config.collectorEndpoint;
}

declare global {
  interface Window {
    // tracer instance, application code will not use this directly
    __yshoppingOtelTracer: ITracer;
  }
}

/**
 * Component to be used to initialize the OpenTelemetry browser SDK
 * with the given configuration. The configuration is expected to be
 * in process.env set in next.js apps, and passed to ConfigContext
 * as props by a layout component or page component.
 */
export const OtelBrowserInit: FC<Props> = ({ requestContext }) => {
  const config = useConfigContext();
  const globalAttributes: Record<string, any> = requestContext;
  const { features, site } = requestContext;
  useEffect(() => {
    if (!window.__yshoppingOtelTracer && isValidConfig(config.otelBrowser)) {
      let { traces } = config.otelBrowser.collectorEndpoint;
      if (traces[0] === "/") {
        traces = window.location.origin + traces;
      }

      const { timestamp: _timestamp, host: _host, ...rest } = globalAttributes;
      window.__yshoppingOtelTracer = tracerProvider.getTracer({
        collectorEndpoint: { traces },
        globalAttributes: rest,
        namingStandards: {
          customNamespace: config.otelBrowser.customNamespace,
        },
        plugins: [
          [
            ClientSamplingTracerPlugin,
            { samplingRate: config.otelBrowser.clientSamplingRate },
          ],
          BenjiTracerPlugin,
          CWVTracerPlugin,
          DocumentLoadTracerPlugin,
          FetchTracerPlugin,
          LongTaskTracerPlugin,
          [UncaughtErrorTracerPlugin, { enableUnhandledRejection: true }],
        ],
        serviceName: config.otelBrowser.serviceName,
      });
    }
  }, [config, globalAttributes]);
  return features.otelBrowserDebug ? (
    <div>
      Environment: {process.env.NODE_ENV}
      <br />
      Client Sampling: {process.env.NEXT_PUBLIC_APP_VERSION}
      <br />
      Client Sampling: {process.env.YAHOO_OTEL_BROWSER_TRACES_SAMPLE_RATE}
      <br />
      otelBrowser Feature: {features.otelBrowser ? "enabled" : "disabled"}
      <br />
      site: {site}
      <br />
    </div>
  ) : null;
};
